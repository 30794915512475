import React from "react";
import ReactDOM from "react-dom";
import Store from "./store";
import Routes from "./routes";
import 'antd/dist/antd.css';
import 'toastr/build/toastr.min.css'
import './index.scss';

import { checkAuth } from "./redux/actions/authAction";

const StoreInstance = Store();

StoreInstance.dispatch(checkAuth());

ReactDOM.render(<Routes store={StoreInstance}/>, document.getElementById("root"));
