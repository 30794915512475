
import * as Types from "../types/types";
import { combineReducers } from "redux";

const adminScreen = (state = "dashboard", action) => {
	switch (action.type) {
	case Types.SET_ADMIN_SCREEN:
		return action.data;
	default:
		return state;
	}
};

const createProjectData = (state = {}, action) => {
	switch (action.type) {
	case Types.GET_CREATEPROJECT_DATA:
		return action.data;
	case Types.CLEAR_CREATEPROJECT_DATA:
		return {};
	default:
		return state;
	}
};

const companyProfile = (state = {}, action) => {
	switch (action.type) {
	case Types.GET_COMPANY_DETAILS:
		return action.data;
	case Types.FAILED_COMPANY_DETAILS:
		return {};
	default:
		return state;
	}
};

const companyProfileLoading = (state = false, action) => {
	switch (action.type) {
	case Types.LOADING_COMPANY_DETAILS:
		return true;
	case Types.GET_COMPANY_DETAILS:
	case Types.FAILED_COMPANY_DETAILS:
		return false;
	default:
		return state;
	}
};

export default combineReducers({
	adminScreen,
	createProjectData,
	companyProfile,
	companyProfileLoading
});

