import React, { Component } from 'react'
import { Row, Col, Icon, Layout, Menu, Divider, Affix, Avatar, Popover, Breadcrumb, Modal, BackTop } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminAction from "./redux/actions/adminAction";
import * as authAction from "./redux/actions/authAction";
import Emojify from "react-emojione"
import toastr from "toastr";
import _ from "lodash";
import './layout.scss';

import Request from "./assets/utils/request"
import SocketContext from './socket-context'
import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";

const MAIN_API = process.env.REACT_APP_MAIN;
const { Header, Content, Sider, Footer } = Layout;
const { SubMenu } = Menu;

const io = sailsIOClient(socketIOClient);
const token = localStorage.getItem("auth") ? localStorage.getItem("auth") : ""

io.sails.headers = { Authorization: `Bearer ${token}` }
io.sails.url = `${MAIN_API}`;
io.sails.useCORSRouteToGetCookie = false

const request = new Request(MAIN_API, token, io);

class layout extends Component {

  state = {
    collapsed: false,
    isDisconnected: false,
    logoutLoading: false,
  }

  componentDidMount() {
    const { actions } = this.props;
    const token = localStorage.getItem("auth") ? localStorage.getItem("auth") : "";

    let interval;

    io.socket.on('disconnect', () => {
      console.log("DISCONNECTED to SERVER");
      this.setState({ isDisconnected: true })
      interval = setInterval(() => {
        if (io.socket.isConnected()) {
          return;
        }

        if (io.socket.isConnecting()) {
          console.log("CONNECTING to SERVER");
          return;
        }

        console.log("RECONNECTING to SERVER");
        io.socket.reconnect();
      }, 5000);
    });

    io.socket.on('connect', () => {
      console.log("CONNECTED TO SERVER");
      this.setState({ isDisconnected: false })
      clearInterval(interval);
    });

    actions.GetCompanyDetails(null, null, { 'x-access-token': token })
    window.scrollTo(0, 0)
  }

  showNavigation = () => {
    const paths = [
      "/",
      "/redirecting"
    ]

    if (paths.includes(this.props.location.pathname)) {
      return "none";
    }

    return "block";
  }

  render() {
    const { location, admin: { companyProfile, companyProfileLoading } } = this.props;
    const { username, profile, role } = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : "";
    const breadcrumbDisplay = _.split(location.pathname, "/")
    const menu = (
      <Menu mode="inline" onClick={this.handleMenuClick}>
        <Menu.Item key="logout" style={{ color: "red" }}>
          {this.state.logoutLoading ?
            <>
              <Icon type="loading" />
              <span className="nav-text">Logging out...</span>
            </> :
            <>
              <Icon type="logout" />
              <span className="nav-text">Logout</span>
            </>
          }
        </Menu.Item>
      </Menu>
    )

    return (
      <div className="main-content-screen">
        <Affix>
          <Header style={{
            display: this.showNavigation(), height: "auto", background: "#001529",
            color: "white", padding: "5px 10px 5px 5px"
          }}>
            <Row type="flex" justify="center" align="middle">
              <Col span={12}>
                <div style={{ display: "inline-block" }}>
                  {this.state.collapsed &&
                    <div style={{ display: "inline-block", minWidth: 70 }}>
                      <div style={{ display: this.state.collapsed ? "block" : "none", textAlign: "center" }}>
                        <Icon
                          type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                          onClick={this.handleCollapse}
                          className="collapsed-icon"
                        />
                      </div>
                    </div>
                  }

                  <div style={{ display: "inline-block" }}>
                    {companyProfileLoading ?
                      <div style={{ width: !this.state.collapsed ? 245 : 70, textAlign: "center" }}>
                        <Icon type="loading" style={{ fontSize: 32 }} />
                      </div> :
                      <div style={{ display: "inline-block" }} className="company-logo-div">
                        {!this.state.collapsed ? <img src={`${MAIN_API}/api/v1/company/logo/1`} alt="logo" className="company-logo" /> :
                          <div style={{ display: "inline-block" }}>
                            <div style={{ textAlign: "center", fontSize: 20 }}>{companyProfile.name}</div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: !this.state.collapsed ? "block" : "none" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon
                      type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.handleCollapse}
                      className="collapsed-icon"
                    />
                  </div>
                </div>
              </Col>
              <Col span={12} align="right">
                <span>
                  {_.upperCase(username)}&nbsp;&nbsp;<Divider type="vertical" />&nbsp;&nbsp;
                  <Popover placement="bottomRight" content={menu} trigger="click" overlayClassName="popover-content-screen">
                    <span style={{ cursor: "pointer" }}><Avatar size={36} style={{ background: "#e8e8e8" }}><Emojify style={{ height: 32, width: 32 }}>
                      {companyProfileLoading ? <Icon type="loading" style={{ color: "black" }} /> : profile && profile[0] && profile[0].avatar}
                    </Emojify></Avatar>&nbsp;&nbsp;<Icon type="down" /></span>
                  </Popover>
                </span>&nbsp;&nbsp;
              </Col>
            </Row>
            <div className="top-navigation">
              {this.renderTopNavigation()}
            </div>
          </Header>
        </Affix>
        <Layout>
          {this.renderSider()}
          <Layout>
            <div style={{ display: role && (role.id === "admin" || role.id === "super-admin") ? "block" : "none" }}>
              <div style={{ background: "#f6f7f8", height: "auto", borderBottom: "1px groove #fdfdfd", padding: 20, display: this.showNavigation() }}>
                <Breadcrumb style={{ fontSize: 18 }}>
                  <Breadcrumb.Item>{_.isEqual(breadcrumbDisplay[2], "manage") ? "Manage Admins" :
                    _.isEqual(breadcrumbDisplay[2], "project-create") ? "Projects" :
                      _.isEqual(breadcrumbDisplay[2], "projects-report") ? "Project Report" :
                        _.isEqual(breadcrumbDisplay[2], "audit-trail") ? "Audit Trail" : _.upperFirst(breadcrumbDisplay[2])}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {_.isEqual(breadcrumbDisplay[3], "main-reports") ? "Main Reports" :
                      _.isEqual(breadcrumbDisplay[3], "machine-cycle-time") ? "Machine Cycle Time" :
                        _.isEqual(breadcrumbDisplay[3], "inspection-and-rework-time") ? "Inspection and Rework Time" :
                          _.isEqual(breadcrumbDisplay[3], "throw-away") ? "Throw Away" :
                            _.isEqual(breadcrumbDisplay[3], "machine-efficiency") ? "Machine Efficiency" :
                              _.isEqual(breadcrumbDisplay[3], "p-chart") ? "P-Chart" : _.upperFirst(breadcrumbDisplay[3])}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <Content>
              <div style={{ padding: _.isEqual(location.pathname, "/") ? 0 : 15, background: 'transparent', minHeight: 360 }}>
                <SocketContext.Provider value={request}>
                  {this.props.children}
                </SocketContext.Provider>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center', color: "white", backgroundColor: "#001529", display: this.showNavigation() }}>IPH Inc. Copyright 2020 Version 4.0</Footer>
          </Layout>
        </Layout>

        <Modal
          visible={this.state.isDisconnected}
          footer={false}
          maskClosable={false}
          closable={false}
          width="400px"
        >
          <Row type="flex" justify="center" align="middle">
            <Col span={24} align="middle">
              <h2>Disconnected from server</h2>
              <h4>Trying to reconnect...</h4>
              <Icon type="loading" />
            </Col>
          </Row>
        </Modal>

        <BackTop>
          <Icon type="up-circle" style={{ fontSize: "42px", color: "black" }} />
        </BackTop>

        <style global="false" jsx="true">
          {`
            .main-content-screen .ant-layout-content {
              margin: ${_.isEqual(location.pathname, "/") ? "0px" : "24px"} !important;
            }
            .ant-layout {
              min-height: 100vh;
              background-color: #f0f3f5;
            }
            .ant-layout-sider-children {
              position: fixed;
              width: ${!this.state.collapsed ? "245px !important" : "80px !important"};
              overflow: auto;
            }
            .ant-layout-sider {
              min-width: ${!this.state.collapsed ? "245px !important" : "80px !important"};
              overflow: auto;
            }
          `}
        </style>
      </div>
    )
  }

  handleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  handleMenuClick = (e) => {
    if (e.key === "logout") {
      return this.handleLogout()
    }
  }

  handleLogout = () => {
    const { actions } = this.props;
    const token = localStorage.getItem("auth") ? localStorage.getItem("auth") : "";

    this.setState({ logoutLoading: true })
    actions.UserLogout(null, { 'x-access-token': token })
      .then((res) => {
        localStorage.clear();
        window.location.href = "/"
      })
      .catch((err) => {
        this.setState({ logoutLoading: false }, () => {
          if (err.response && err.response.data && err.response.data.message && err.response.data.message === "Unauthorized.") {
            toastr.info("Session is expired.")
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/"
            }, 3000)
          } else {
            toastr.error(err.response && err.response.data.message ? err.response.data.message : "Something went wrong!")
          }
        })
      })
  }

  renderSider = () => {
    const { role, username } = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : "";
    const { location } = this.props;

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={this.state.collapsed}
        style={{ display: this.showNavigation() }}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={['sub1', 'sub2']}
          selectedKeys={[_.isEqual(location.pathname, "/admin/project-create") ? "/admin/projects" :
            location.pathname.includes("/employee/projects/inprogress") ? "/employee/in-progress" :
              location.pathname.includes("/employee/projects/report") ? "/employee/completed" :
                location.pathname.includes("/admin/projects-report") ? "/admin/dashboard" :
                  location.pathname
          ]}
          onClick={this.handleMenuClick}
        >
          {role && role.id === "employee" ?
            <Menu.Item key="/employee/in-progress">
              <Link to="/employee/in-progress">
                <Icon type="project" />
                <span className="nav-text">Project In-Progress</span>
              </Link>
            </Menu.Item> : null
          }
          {role && role.id === "employee" ?
            <Menu.Item key="/employee/completed">
              <Link to="/employee/completed">
                <Icon type="project" />
                <span className="nav-text">Project Completed</span>
              </Link>
            </Menu.Item> : null
          }
          {role && role.id === "employee" ?
            <Menu.Item key="/employee/lists">
              <Link to="/employee/lists">
                <Icon type="project" />
                <span className="nav-text">Project Lists</span>
              </Link>
            </Menu.Item> : null
          }

          {role && ((role.id === "admin" || role.id === "super-admin")) && username && username !== "AdminPCHART" ?
            <Menu.Item key="/admin/dashboard" style={{ marginTop: 0 }}>
              <Link to="/admin/dashboard">
                <Icon type="dashboard" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </Menu.Item> : null
          }
          {role && role.id === "super-admin" ?
            <Menu.Item key="/admin/manage">
              <Link to="/admin/manage">
                <Icon type="user" />
                <span className="nav-text">Manage Admins</span>
              </Link>
            </Menu.Item> : null
          }
          {role && ((role.id === "admin" || role.id === "super-admin")) && username && username !== "AdminPCHART" ?
            <Menu.Item key="/admin/projects">
              <Link to="/admin/projects">
                <Icon type="project" />
                <span className="nav-text">Projects</span>
              </Link>
            </Menu.Item> : null
          }
          {role && ((role.id === "admin" || role.id === "super-admin")) && username && username !== "AdminPCHART" ?
            <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="api" />
                  <span>Configuration</span>
                </span>
              }
            >
              <Menu.Item key="/admin/configuration/profile">
                <Link to="/admin/configuration/profile">
                  <span className="nav-text">Profile</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/configuration/process">
                <Link to="/admin/configuration/process">
                  <span className="nav-text">Process</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/configuration/employee">
                <Link to="/admin/configuration/employee">
                  <span className="nav-text">Employee</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/configuration/products">
                <Link to="/admin/configuration/products">
                  <span className="nav-text">Products</span>
                </Link>
              </Menu.Item>
            </SubMenu> : null
          }
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" ?
            <SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="bar-chart" />
                  <span className="nav-text">Reports</span>
                </span>
              }
            >
              <Menu.Item key="/admin/reports/main-reports">
                <Link to="/admin/reports/main-reports">
                  <span className="nav-text">Main Reports</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/reports/machine-cycle-time">
                <Link to="/admin/reports/machine-cycle-time">
                  <span className="nav-text">Machine Cycle Time</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/reports/inspection-and-rework-time">
                <Link to="/admin/reports/inspection-and-rework-time">
                  <span className="nav-text">Inspection and Rework Time</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/reports/throw-away">
                <Link to="/admin/reports/throw-away">
                  <span className="nav-text">Throw Away</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/reports/machine-efficiency">
                <Link to="/admin/reports/machine-efficiency">
                  <span className="nav-text">Machine Efficiency</span>
                </Link>
              </Menu.Item>
            </SubMenu> : null
          }
          {role && (role.id === "admin" || role.id === "super-admin") && username && username === "AdminPCHART" ?
            <SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="bar-chart" />
                  <span className="nav-text">Reports</span>
                </span>
              }
            >
              <Menu.Item key="/admin/reports/p-chart">
                <Link to="/admin/reports/p-chart">
                  <span className="nav-text">P-Chart</span>
                </Link>
              </Menu.Item>
            </SubMenu> : null
          }
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" ?
            <Menu.Item key="/admin/audit-trail">
              <Link to="/admin/audit-trail">
                <Icon type="audit" />
                <span className="nav-text">Audit Trail</span>
              </Link>
            </Menu.Item> : null
          }
          <Menu.Item key="logout">
            {this.state.logoutLoading ?
              <>
                <Icon type="loading" />
                <span className="nav-text">Logging out...</span>
              </> :
              <>
                <Icon type="logout" />
                <span className="nav-text">Logout</span>
              </>
            }
          </Menu.Item>
        </Menu>
      </Sider>
    )
  }

  renderTopNavigation = () => {
    const { role, username } = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : "";
    const { location } = this.props;

    return (
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[_.isEqual(location.pathname, "/admin/project-create") ? "/admin/projects" :
          location.pathname.includes("/employee/projects/inprogress") ? "/employee/in-progress" :
            location.pathname.includes("/employee/projects/report") ? "/employee/completed" :
              location.pathname.includes("/admin/projects-report") ? "/admin/dashboard" :
                location.pathname
        ]}
        onClick={this.handleMenuClick}
      >
        {role && role.id === "employee" ?
          <Menu.Item key="/employee/in-progress">
            <Link to="/employee/in-progress">
              <Icon type="project" />
              <span className="nav-text">Project In-Progress</span>
            </Link>
          </Menu.Item> : null
        }
        {role && role.id === "employee" ?
          <Menu.Item key="/employee/completed">
            <Link to="/employee/completed">
              <Icon type="project" />
              <span className="nav-text">Project Completed</span>
            </Link>
          </Menu.Item> : null
        }
        {role && role.id === "employee" ?
          <Menu.Item key="/employee/lists">
            <Link to="/employee/lists">
              <Icon type="project" />
              <span className="nav-text">Project Lists</span>
            </Link>
          </Menu.Item> : null
        }

        {role && ((role.id === "admin" || role.id === "super-admin")) && username && username !== "AdminPCHART" ?
          <Menu.Item key="/admin/dashboard" style={{ marginTop: 0 }}>
            <Link to="/admin/dashboard">
              <Icon type="dashboard" />
              <span className="nav-text">Dashboard</span>
            </Link>
          </Menu.Item> : null
        }
        {role && role.id === "super-admin" ?
          <Menu.Item key="/admin/manage">
            <Link to="/admin/manage">
              <Icon type="user" />
              <span className="nav-text">Manage Admins</span>
            </Link>
          </Menu.Item> : null
        }
        {role && ((role.id === "admin" || role.id === "super-admin")) && username && username !== "AdminPCHART" ?
          <Menu.Item key="/admin/projects">
            <Link to="/admin/projects">
              <Icon type="project" />
              <span className="nav-text">Projects</span>
            </Link>
          </Menu.Item> : null
        }
        {role && ((role.id === "admin" || role.id === "super-admin")) && username && username !== "AdminPCHART" ?
          <SubMenu
            key="sub1"
            title={
              <span>
                <Icon type="api" />
                <span>Configuration</span>
              </span>
            }
          >
            <Menu.Item key="/admin/configuration/profile">
              <Link to="/admin/configuration/profile">
                <span className="nav-text">Profile</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/configuration/process">
              <Link to="/admin/configuration/process">
                <span className="nav-text">Process</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/configuration/employee">
              <Link to="/admin/configuration/employee">
                <span className="nav-text">Employee</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/configuration/products">
              <Link to="/admin/configuration/products">
                <span className="nav-text">Products</span>
              </Link>
            </Menu.Item>
          </SubMenu> : null
        }
        {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" ?
          <SubMenu
            key="sub2"
            title={
              <span>
                <Icon type="bar-chart" />
                <span className="nav-text">Reports</span>
              </span>
            }
          >
            <Menu.Item key="/admin/reports/main-reports">
              <Link to="/admin/reports/main-reports">
                <span className="nav-text">Main Reports</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/reports/machine-cycle-time">
              <Link to="/admin/reports/machine-cycle-time">
                <span className="nav-text">Machine Cycle Time</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/reports/inspection-and-rework-time">
              <Link to="/admin/reports/inspection-and-rework-time">
                <span className="nav-text">Inspection and Rework Time</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/reports/throw-away">
              <Link to="/admin/reports/throw-away">
                <span className="nav-text">Throw Away</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/reports/machine-efficiency">
              <Link to="/admin/reports/machine-efficiency">
                <span className="nav-text">Machine Efficiency</span>
              </Link>
            </Menu.Item>
          </SubMenu> : null
        }
        {role && (role.id === "admin" || role.id === "super-admin") && username && username === "AdminPCHART" ?
          <SubMenu
            key="sub2"
            title={
              <span>
                <Icon type="bar-chart" />
                <span className="nav-text">Reports</span>
              </span>
            }
          >
            <Menu.Item key="/admin/reports/p-chart">
              <Link to="/admin/reports/p-chart">
                <span className="nav-text">P-Chart</span>
              </Link>
            </Menu.Item>
          </SubMenu> : null
        }
        {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" ?
          <Menu.Item key="/admin/audit-trail">
            <Link to="/admin/audit-trail">
              <Icon type="audit" />
              <span className="nav-text">Audit Trail</span>
            </Link>
          </Menu.Item> : null
        }
        <Menu.Item key="logout">
          {this.state.logoutLoading ?
            <>
              <Icon type="loading" />
              <span className="nav-text">Logging out...</span>
            </> :
            <>
              <Icon type="logout" />
              <span className="nav-text">Logout</span>
            </>
          }
        </Menu.Item>
      </Menu>
    )
  }
}

const mapStateToProps = ({ auth, admin }) => ({
  auth,
  admin
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(adminAction, dispatch),
  authAction: bindActionCreators(authAction, dispatch),
});

const layoutMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(layout);

export default withRouter(connect(mapStateToProps)(layoutMain));