// auth
export const SAVE_AUTH_SUCCESS = "SAVE_AUTH_SUCCESS";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";

// admin
export const SET_ADMIN_SCREEN = "SET_ADMIN_SCREEN";

// projects
export const GET_CREATEPROJECT_DATA = "GET_CREATEPROJECT_DATA";
export const CLEAR_CREATEPROJECT_DATA = "CLEAR_CREATEPROJECT_DATA";

// company details
export const LOADING_COMPANY_DETAILS = "LOADING_COMPANY_DETAILS";
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const FAILED_COMPANY_DETAILS = "FAILED_COMPANY_DETAILS";
