import axios from "axios";
const MAIN_API = process.env.REACT_APP_MAIN;

export function Auth(data, header) {
	return {
		// client
		UserLogin: () => axios.post(`${MAIN_API}/api/v1/user/login`, data),
		UserLogout: () => axios.get(`${MAIN_API}/api/v1/user/logout`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}}),
	};
}

export function Company(query, params, header) {
	return {
		// clear data
		DeleteCompanyData: () => axios.delete(`${MAIN_API}/api/v1/clear`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}, data: params}),

		// client
		GetCompanyDetails: () => axios.get(`${MAIN_API}/api/v1/company`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateCompanyDetails: () => axios.patch(`${MAIN_API}/api/v1/company`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UploadCompanyLogo: () => axios.patch(`${MAIN_API}/api/v1/company/logo/${query.id}`, params.logo, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// manage admins
		GetAdmins: () => axios.get(`${MAIN_API}/api/v1/users/admin`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// projects
		UpdateProjectStatus: () => axios.patch(`${MAIN_API}/api/v1/process/status`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		GetProject: () => axios.get(`${MAIN_API}/api/v1/projects${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		CreateProject: () => axios.post(`${MAIN_API}/api/v1/project`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateProject: () => axios.patch(`${MAIN_API}/api/v1/project`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeleteProject: () => axios.delete(`${MAIN_API}/api/v1/project/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewProjectId: () => axios.get(`${MAIN_API}/api/v1/project/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewProjectLotNumber: () => axios.get(`${MAIN_API}/api/v1/project/processed/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		GetProcessEmployee: () => axios.get(`${MAIN_API}/api/v1/process/${query}/users`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// configuration - process
		GetProcess: () => axios.get(`${MAIN_API}/api/v1/process${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddProcess: () => axios.post(`${MAIN_API}/api/v1/process`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateProcess: () => axios.patch(`${MAIN_API}/api/v1/process`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeleteProcess: () => axios.delete(`${MAIN_API}/api/v1/process/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewFieldProcess: () => axios.get(`${MAIN_API}/api/v1/process/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// configuration - employee
		GetEmployee: () => axios.get(`${MAIN_API}/api/v1/users${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddEmploye: () => axios.post(`${MAIN_API}/api/v1/user`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateEmployee: () => axios.patch(`${MAIN_API}/api/v1/user`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateEmployeeStatus: () => axios.patch(`${MAIN_API}/api/v1/user/${query.id}/${query.status}`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewEmployee: () => axios.get(`${MAIN_API}/api/v1/user/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// configuration - products
		// machine
		GetMachine: () => axios.get(`${MAIN_API }/api/v1/machines${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddMachine: () => axios.post(`${MAIN_API}/api/v1/machine`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateMachine: () => axios.patch(`${MAIN_API}/api/v1/machine`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeleteMachine: () => axios.delete(`${MAIN_API}/api/v1/machine/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewMachine: () => axios.get(`${MAIN_API}/api/v1/machine/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// mold
		GetMold: () => axios.get(`${MAIN_API}/api/v1/molds${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddMold: () => axios.post(`${MAIN_API}/api/v1/mold`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateMold: () => axios.patch(`${MAIN_API}/api/v1/mold`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeleteMold: () => axios.delete(`${MAIN_API}/api/v1/mold/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewMold: () => axios.get(`${MAIN_API}/api/v1/mold/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		
		// part or material number
		GetPart: () => axios.get(`${MAIN_API}/api/v1/parts${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddPart: () => axios.post(`${MAIN_API}/api/v1/part`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdatePart: () => axios.patch(`${MAIN_API}/api/v1/part`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeletePart: () => axios.delete(`${MAIN_API}/api/v1/part/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewPart: () => axios.get(`${MAIN_API}/api/v1/part/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// defect codes
		GetDefect: () => axios.get(`${MAIN_API}/api/v1/defect-codes${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddDefect: () => axios.post(`${MAIN_API}/api/v1/defect-code`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateDefect: () => axios.patch(`${MAIN_API}/api/v1/defect-code`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeleteDefect: () => axios.delete(`${MAIN_API}/api/v1/defect-code/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewDefect: () => axios.get(`${MAIN_API}/api/v1/defect-code/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// problems
		GetProblem: () => axios.get(`${MAIN_API}/api/v1/problem${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		AddProblem: () => axios.post(`${MAIN_API}/api/v1/problem`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		UpdateProblem: () => axios.patch(`${MAIN_API}/api/v1/problem`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		DeleteProblem: () => axios.delete(`${MAIN_API}/api/v1/problem/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewProblem: () => axios.get(`${MAIN_API}/api/v1/problem/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// reports
		GetReports: () => axios.post(`${MAIN_API}/api/v1/project/reports`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		GetProjectDropdown: () => axios.get(`${MAIN_API}/api/v1/projects/projects/dropdown/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		ViewAdminProjectReport: () => axios.get(`${MAIN_API}/api/v1/projects/admin/report/${query.id}/${query.reportDate}`, 
		{headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		EditReports: () => axios.post(`${MAIN_API}/api/v1/process/edit`, params, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// machine cycle time
		GetMachineCycleTimeReport: () => axios.get(`${MAIN_API}/api/v1/report/machine-cycle-time${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// inspection and rework time
		GetInspectionCycleTimeReport: () => axios.get(`${MAIN_API}/api/v1/report/inspection-cycle-time${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// throw away
		GetThrowAwayReport: () => axios.get(`${MAIN_API}/api/v1/report/throw-away${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// machine efficiency
		GetMachineEfficiencyReport: () => axios.get(`${MAIN_API}/api/v1/report/machine-efficiency${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// p-chart
		GetPChartReport: () => axios.get(`${MAIN_API}/api/v1/report/p-chart${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// audit trail
		GetUserActivities: () => axios.get(`${MAIN_API}/api/v1/user/activities${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// admin dashboard
		GetAdminDashboard: () => axios.get(`${MAIN_API}/api/v1/admin/dashboard${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// employee dashboard 
		GetEmployeeDashboard: () => axios.get(`${MAIN_API}/api/v1/employee/dashboard`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		// project lists
		GetProjectLists: () => axios.get(`${MAIN_API}/api/v1/employee/projects/lists${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		// project in progress
		GetProjectInProgress: () => axios.get(`${MAIN_API}/api/v1/employee/projects/in-progress${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
		// project completed
		GetProjectCompleted: () => axios.get(`${MAIN_API}/api/v1/employee/projects/completed${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),

		GetProjectReport: () => axios.get(`${MAIN_API}/api/v1/project/completed/${query}`, {headers:{'Authorization':`Bearer ${header['x-access-token']}`}} ),
	};
}