import React from 'react'
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { hasToken } from "./assets/utils/auth";
import Loadable from "react-loadable";
import LoadingComponent from "./components/general/LoadingComponent";
import Layout from './layout'

const Redirecting = Loadable({ loader: () => import("./components/general/RedirectComponent"), loading: LoadingComponent });

const LoginScreen = Loadable({ loader: () => import("./components/login/LoginScreen"), loading: LoadingComponent });

// ADMIN
const AdminDashboardScreen = Loadable({ loader: () => import("./components/admin/dashboard/DashboardScreen"), loading: LoadingComponent });
const AdminManageAdminsScreen = Loadable({ loader: () => import("./components/admin/manageAdmins/ManageAdminsScreen"), loading: LoadingComponent });
const AdminProjectsScreen = Loadable({ loader: () => import("./components/admin/projects/ProjectsScreen"), loading: LoadingComponent });
const AdminProjectsCreateScreen = Loadable({ loader: () => import("./components/admin/projects/ProjectsScreenCreateProject"), loading: LoadingComponent });

// configuration
const AdminProfileScreen = Loadable({ loader: () => import("./components/admin/configuration/profile/ProfileScreen"), loading: LoadingComponent });
const AdminProcessScreen = Loadable({ loader: () => import("./components/admin/configuration/process/ProcessScreen"), loading: LoadingComponent });
const AdminEmployeeScreen = Loadable({ loader: () => import("./components/admin/configuration/employee/EmployeeScreen"), loading: LoadingComponent });
const AdminProductsScreen = Loadable({ loader: () => import("./components/admin/configuration/products/ProductsScreen"), loading: LoadingComponent });

const AdminAuditTrailScreen = Loadable({ loader: () => import("./components/admin/audit/AuditTrailScreen"), loading: LoadingComponent });

// main reports
const AdminReportsScreen = Loadable({ loader: () => import("./components/admin/reports/MainReports/ReportsScreen"), loading: LoadingComponent });
const AdminProjectReport = Loadable({ loader: () => import("./components/admin/reports/MainReports/ProjectReportScreen"), loading: LoadingComponent });
const AdminMachineCycleTimeReport = Loadable({ loader: () => import("./components/admin/reports/MachineCycleTime/MachineCycleTimeScreen"), loading: LoadingComponent });
const AdminInspectionAndReworkTimeReport = Loadable({ loader: () => import("./components/admin/reports/InspectionAndReworkTime/InspectionAndReworkTimeScreen"), loading: LoadingComponent });
const AdminThrowAwayReport = Loadable({ loader: () => import("./components/admin/reports/ThrowAway/ThrowAwayScreen"), loading: LoadingComponent });
const AdminMachineEfficiencyReport = Loadable({ loader: () => import("./components/admin/reports/MachineEfficiency/MachineEfficiencyScreen"), loading: LoadingComponent });
const AdminPChartReport = Loadable({ loader: () => import("./components/admin/reports/PChart/PChartScreen"), loading: LoadingComponent });

// EMPLOYEE
// const EmployeeDashboardScreen = Loadable({ loader: () => import("./components/employee/dashboard/DashboardScreen"), loading: LoadingComponent });
// const EmployeeProjectsScreenId = Loadable({ loader: () => import("./components/employee/projects/ProjectsLists/ProjectsScreen"), loading: LoadingComponent });
const EmployeeProjectsScreenTrackingNumber = Loadable({ loader: () => import("./components/employee/projects/ProjectsInProgress/ProjectsScreen"), loading: LoadingComponent });

const EmployeeProjectsInProgressScreen = Loadable({ loader: () => import("./components/employee/projects/ProjectsInProgress/ProjectsInProgress"), loading: LoadingComponent });
const EmployeeProjectsCompletedScreen = Loadable({ loader: () => import("./components/employee/projects/ProjectsCompleted/ProjectsCompleted"), loading: LoadingComponent });
const EmployeeProjectsListsScreen = Loadable({ loader: () => import("./components/employee/projects/ProjectsLists/ProjectsLists"), loading: LoadingComponent });

const EmployeeProjectReport = Loadable({ loader: () => import("./components/employee/reports/ReportsScreen"), loading: LoadingComponent });

const history = createBrowserHistory();

const { role, username } = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : "";

const Routes = ({ store }) => (
  <Provider store={store}>
    <Router history={history}>
      <Layout>
        <Switch>
          <PublicRoutes path="/" component={LoginScreen} exact />
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" && 
            <PrivateRoutes path="/admin/dashboard" component={AdminDashboardScreen} />}
          {role && (role.id === "super-admin") &&
            <PrivateRoutes path="/admin/manage" component={AdminManageAdminsScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/projects" component={AdminProjectsScreen} />}
          {role && (role.id === "admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/project-create" component={AdminProjectsCreateScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/configuration/profile" component={AdminProfileScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/configuration/process" component={AdminProcessScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/configuration/employee" component={AdminEmployeeScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/configuration/products" component={AdminProductsScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/reports/main-reports" component={AdminReportsScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/audit-trail" component={AdminAuditTrailScreen} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/projects-report/:trackingNumber" component={AdminProjectReport} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/reports/machine-cycle-time" component={AdminMachineCycleTimeReport} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/reports/inspection-and-rework-time" component={AdminInspectionAndReworkTimeReport} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/reports/throw-away" component={AdminThrowAwayReport} />}
          {role && (role.id === "admin" || role.id === "super-admin") && username && username !== "AdminPCHART" &&
            <PrivateRoutes path="/admin/reports/machine-efficiency" component={AdminMachineEfficiencyReport} />}
          {username && username === "AdminPCHART" && <PrivateRoutes path="/admin/reports/p-chart" component={AdminPChartReport} />}

          {role && role.id === "employee" && <PrivateRoutes path="/employee/in-progress" component={EmployeeProjectsInProgressScreen} />}
          {role && role.id === "employee" && <PrivateRoutes path="/employee/completed" component={EmployeeProjectsCompletedScreen} />}
          {role && role.id === "employee" && <PrivateRoutes path="/employee/lists" component={EmployeeProjectsListsScreen} />}
          {/* {role && role.id === "employee" && <PrivateRoutes path="/employee/projects/lists/:id" component={EmployeeProjectsScreenId} />} */}
          {role && role.id === "employee" && <PrivateRoutes path="/employee/projects/inprogress/:trackingNumber/:isEdit?" component={EmployeeProjectsScreenTrackingNumber} />}
          {role && role.id === "employee" && <PrivateRoutes path="/employee/projects/report/:trackingNumber" component={EmployeeProjectReport} />}

          <Route path="/redirecting" component={Redirecting} />
          <Redirect to="/" component={Redirecting} />
        </Switch>
      </Layout>
    </Router>
  </Provider>
);

const PublicRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasToken() ? <Redirect to="/redirecting" /> : <Component {...props} />
      }
    />
  );
};

const PrivateRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasToken()) {
          return <Component {...props} />;
        } else {
          window.location.href = "/";
        }
      }}
    />
  )
}

export default Routes;