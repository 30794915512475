import * as Types from "../types/types";
import { Auth, Company } from "../../api/api";

// SET ADMIN SCREEN
export const AdminScreen = (key) => ({
	type: Types.SET_ADMIN_SCREEN,
	data: key,
});

// ADMIN LOGIN
export const UserLogin = (data) => (
	async(dispatch) => {
		try {
			const result = await Auth(data).UserLogin();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UserLogout = (data, headers) => (
	async(dispatch) => {
		try {
			const result = await Auth(data, headers).UserLogout();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// CLEAR DATA
export const DeleteCompanyData = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteCompanyData();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// COMPANY DETAILS
export const GetCompanyDetails = (query, params, header) => (
	async(dispatch) => {
		try {
			dispatch({ type: Types.LOADING_COMPANY_DETAILS });

			const result = await Company(query, params, header).GetCompanyDetails();
			dispatch({ type: Types.GET_COMPANY_DETAILS, data: result.data });

			return result;
		} catch (e) {

			dispatch({ type: Types.FAILED_COMPANY_DETAILS, error: e });
			throw e;
		}
	}
);

export const UpdateCompanyDetails = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateCompanyDetails();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UploadCompanyLogo = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UploadCompanyLogo();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// MANAGE ADMINS
export const GetAdmins = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetAdmins();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// PROJECTS
export const ClearProjectData = (data) => {
	return (dispatch) => {
		dispatch({ type: Types.CLEAR_CREATEPROJECT_DATA, data });
	};
}

export const CreateProjectData = (data) => ({
	type: Types.GET_CREATEPROJECT_DATA,
	data,
});

export const UpdateProjectStatus = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateProjectStatus();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const GetProject = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProject();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const CreateProject = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).CreateProject();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateProject = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateProject();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeleteProject = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteProject();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewProjectId = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewProjectId();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewProjectLotNumber = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewProjectLotNumber();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const GetProcessEmployee = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProcessEmployee();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// CONFIGURATION - PROCESS
export const GetProcess = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProcess();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddProcess = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddProcess();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateProcess = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateProcess();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeleteProcess = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteProcess();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewFieldProcess = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewFieldProcess();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// CONFIGURATION - EMPLOYEE
export const GetEmployee = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetEmployee();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddEmploye = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddEmploye();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateEmployee = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateEmployee();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateEmployeeStatus = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateEmployeeStatus();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewEmployee = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewEmployee();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// CONFIGURATION - PRODUCTS
// MACHINE
export const GetMachine = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetMachine();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddMachine = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddMachine();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateMachine = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateMachine();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeleteMachine = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteMachine();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewMachine = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewMachine();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// MOLD
export const GetMold = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetMold();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddMold = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddMold();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateMold = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateMold();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeleteMold = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteMold();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewMold = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewMold();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// PART OR MATERIAL NUMBER
export const GetPart = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetPart();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddPart = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddPart();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdatePart = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdatePart();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeletePart = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeletePart();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewPart = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewPart();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// DEFECT CODES
export const GetDefect = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetDefect();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddDefect = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddDefect();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateDefect = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateDefect();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeleteDefect = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteDefect();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewDefect = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewDefect();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// PROBLEMS
export const GetProblem = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProblem();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const AddProblem = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).AddProblem();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const UpdateProblem = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).UpdateProblem();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const DeleteProblem = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).DeleteProblem();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewProblem = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewProblem();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// REPORTS
export const GetProjectDropdown = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProjectDropdown();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const ViewAdminProjectReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).ViewAdminProjectReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const GetReports = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetReports();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const EditReports = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).EditReports();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// MACHINE CYCLE TIME
export const GetMachineCycleTimeReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetMachineCycleTimeReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// INSEPCTION AND REWORK TIME
export const GetInspectionCycleTimeReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetInspectionCycleTimeReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// THROW AWAY
export const GetThrowAwayReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetThrowAwayReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// MACHINE EFFICIENCY
export const GetMachineEfficiencyReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetMachineEfficiencyReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// PCHART
export const GetPChartReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetPChartReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// AUDIT TRAIL
export const GetUserActivities = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetUserActivities();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// EMPLOYEE DASHBOARD
export const GetEmployeeDashboard = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetEmployeeDashboard();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// ADMIN DASHBOARD
export const GetAdminDashboard = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetAdminDashboard();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

// EMPLOYEE PROJECTS 
export const GetProjectLists = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProjectLists();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const GetProjectInProgress = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProjectInProgress();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const GetProjectCompleted = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProjectCompleted();

			return result;
		} catch (e) {
			throw e;
		}
	}
);

export const GetProjectReport = (query, params, header) => (
	async(dispatch) => {
		try {
			const result = await Company(query, params, header).GetProjectReport();

			return result;
		} catch (e) {
			throw e;
		}
	}
);