import React from "react";
import { Spin, Icon } from "antd";

const antIcon = <Icon type="loading" style={{ fontSize: 32 }} spin />;

const LoadingComponent = ({ isLoading, error }) => {
	// Handle the loading state
	if (isLoading) {
		return (
			<div className="" style={{ display: "flex", alignItems: "center", minHeight: "93vh" }}>
				<div className="" style={{ width: "100%", textAlign: "center" }}>
					<Spin indicator={antIcon}/>
				</div>
			</div>
		);
	} else if (error) {
		return (
			<div className="" style={{ display:"flex", alignItems:"center", minHeight: "93vh" }}>
				<div className="" style={{ width:"100%", textAlign:"center" }}>
          Sorry, there was a problem loading the page.
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default LoadingComponent;