import axios from "axios";
class Request {
	constructor(host, token, io) {
		this.host = host;
		this.io = io;
		this.token = token;
	}
	get = async (route, where = {}, skip = 0, limit = 30, sort = []) => {
		const query = this._prepareQuery(where);
		const s = this._prepareQuery(sort);
		return await this._request(`${route}?where=${query}&skip=${skip}&limit=${limit}&sort=${s}`, "GET");
	};
	post = async (route, body) => await this._request(route, "POST", body);
	patch = (route, body) => this._request(route, "PATCH", body);
	put = (route, body) => this._request(route, "PUT", body);
	delete = (route) => this._request(route, "DELETE");
	_request = async (route, method, body) => {
		try {
			const payload = {
				method,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			};
			if (method !== "GET" && method !== "HEAD") {
				if (this.io) {
					payload.params = body;
				} else {
					payload.data = JSON.stringify(body);
				}
			}
			if (this.token) {
				payload.headers.Authorization = `Bearer ${this.token}`;
			}
			const url = `${this.host}${route}`;
			if (this.io) {
				return await this._sendSocketRequest(url, payload);
			}
			return await this._sendHttpRequest(url, payload);
		} catch (err) {
			throw err;
		}
	};
	_sendHttpRequest = async (url, payload) => {
		const response = await axios(url, payload)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
					throw error.response.data;
				} else if (error.request) {
					console.log(error.request);
					console.log(error.message);
					throw error.message;
				} else {
					console.log("Error", error.message);
					throw error.message;
				}
				// console.log(error.config);
			});
		return response;
	};
	_sendSocketRequest = async (url, payload) => {
		try {
			const response = await new Promise((resolve, reject) => {
				payload.url = url;
				this.io.socket.request(payload, (resData, jwres) => {
					if (jwres.statusCode !== 200) {
						return reject(jwres);
					}
					return resolve(resData);
				});
			});
			return response;
		} catch (err) {
			console.error(err);
			throw err;
		}
	};
	_prepareQuery = (query) => encodeURIComponent(JSON.stringify(query));
}
export default Request;